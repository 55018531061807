/* 
This was really the only way i could modify the stupid react-date picker styles
popper.js does some stuff in the background and only regular css 
could fix it :( 
*/

.react-datepicker__header {
	background-color: #000000;
	padding: 0px;
	padding-bottom: 7px;
}

.react-datepicker__day-names {
	background-color: #f9fafb;
}

.react-datepicker__day-name {
	padding-top: 5px;
	font-size: 11px;
	height: 26px;
	width: 32px;
	color: #8f95b2;
}

.react-datepicker__current-month {
	color: whitesmoke;
	font-size: 12px;
	padding: 9px !important;
}

.react-datepicker__navigation {
	scale: 0.6;
	top: 0px;
}

.react-datepicker__day {
	padding-top: 5px;
	font-size: 11px;
	height: 26px;
	width: 32px;
}

.react-datepicker__today-button {
	font-size: 12px;
	background-color: #000000;
	color: whitesmoke;
}

.react-datepicker__day:hover {
	background-color: #75757553 !important;
}

.react-datepicker__day--in-range {
	background-color: #edeef4 !important;
	outline: 1px solid #c0c0c0;
	color: #212121;
	font-weight: 600 !important;
}

.react-datepicker__day--today {
	background-color: #e3e3ec !important;
	transition: 200ms;
	font-weight: normal;
}

.react-datepicker__month-container {
	float: none !important;
}

.react-datepicker__month {
	margin: 0 !important;
}

.react-datepicker__day--in-range:hover,
.react-datepicker__day--in-selecting-range {
	background-color: #edeef4 !important;
	color: #212121;
}

.react-datepicker__day--selected {
	background-color: #edeef4;
	outline: 1px solid #c0c0c0;
	font-weight: 600;
	color: #212121;
}

.react-datepicker__day--keyboard-selected {
	background-color: transparent;
}

.react-datepicker__day--disabled:hover {
	background-color: transparent !important;
	cursor: pointer;
}
